import React from "react"
import { Link } from "gatsby"
import logo from "../../images/logo.png"

export const Header = () => {
  return (
    <header>
      <div>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={logo}
            alt="Creative Train Company"
            className="logo"
            style={{ marginRight: 20 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1>Creative Train Company</h1>
            <p className="tagline">
              Custom 7.25&quot;/7.5&quot; Gauge and 1.5&quot;/1.6&quot; Scale
              Train Cars
            </p>
            <p style={{ textAlign: "right" }}>
              Check us out on{" "}
              <a href="https://www.facebook.com/creativetraincompany/">
                Facebook
              </a>{" "}
              and <a href="https://www.instagram.com/yrnehfehr/">Instagram</a>
            </p>
          </div>
        </div>

        <nav>
          <div className="container">
            <Link to="/">Home</Link>
            <Link to="/rolling-stock">Rolling Stock</Link>
            <Link to="/figures">Figures</Link>
            <Link to="/accessories">Accessories</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </nav>
      </div>
    </header>
  )
}
