/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SimpleReactLightbox from "simple-react-lightbox"
import { Footer } from "./footer"
import { Header } from "./header"

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <SimpleReactLightbox>
        <main>{children}</main>
      </SimpleReactLightbox>
      <Footer />
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "Organization",
          "url": "https://www.creativetrains.ca",
          "name": "Creative Train Company",
          "logo": "https://www.creativetrains.ca/images/original/logo.png",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-204-332-0492",
            "email": "henry@creativetrains.ca",
            "contactType": "Sales"
          }
        }`}
      </script>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
